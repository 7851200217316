import React, { ReactElement } from "react";

import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import BestMoversNearbyWrapper from "@components/layouts/bestMoversNearbyWrapper";

import MainForm from "@components/bestmoversnearby/mainBanner";

const Benefits = dynamic(() => import("@components/bestmoversnearby/benefits"));
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function BestMoversNearbyHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <BestMoversNearbyWrapper>
            <MainForm />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Benefits />
        </BestMoversNearbyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
